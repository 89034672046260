import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";

export default function LabelledButton({ direction='left', label, icon, background, color, onClick }){

    const [hovered, setHovered]=useState(false);

    const mobile=false;
    const [clickedOnce, setClickedOnce]=useState(false);

    return(
        <Box
            onClick={()=>{
                if(mobile){
                    if(clickedOnce){
                        onClick();
                        setClickedOnce(false);
                    }
                    else{
                        setHovered(true);
                        setClickedOnce(true);
                        setTimeout(()=>{
                            setHovered(false);
                            setClickedOnce(false);
                        }, 1500);
                    }
                }
                else{
                    onClick();
                }
            }}
            onMouseEnter={()=>setHovered(true)}
            onMouseLeave={()=>setHovered(false)}
            sx={{
                display:'flex',
                flexDirection: direction==='left'? 'row' : 'row-reverse',
                alignItems:'center',
                justifyContent:'flex-end',
                cursor:'pointer'
            }}
        >
            <Box
                sx={{
                    m: direction==='left'? '0 -0.5rem 0 0' : '0 0 0 -0.5rem'
                }}
            >
                <Collapse in={hovered} orientation='horizontal'>
                    <Box
                        sx={{
                            p: direction==='left'? '0.25rem 1.5rem 0.25rem 0.5rem' : '0.25rem 0.5rem 0.25rem 1.5rem',
                            background:background,
                            color:color,
                        }}
                    >
                        <Typography
                            sx={{
                                fontSize:'0.8rem',
                                fontWeight:600,
                                whiteSpace:'nowrap'
                            }}
                        >
                            {label}
                        </Typography>
                    </Box>
                </Collapse>
            </Box>
            <Box
                sx={{
                    width:'2.5rem',
                    height:'2.5rem',
                    background:background,
                    color:color,
                    borderRadius:'50%',
                    display:'flex',
                    alignItems:'center',
                    justifyContent:'center',
                    filter:'drop-shadow(0 0 0.5rem #fff)'
                }}
            >
                <FontAwesomeIcon
                    icon={icon}
                    style={{
                        fontSize:'1.25rem',
                        cursor:'pointer'
                    }}
                />
            </Box>
        </Box>
    );
}